/**
 * Login container.
 * @module components/theme/Login/Login
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import {
    Container,
    Button,
    Form,
    Input,
    Segment,
    Grid,
    Message
} from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

import { Icon } from '@plone/volto/components';
import { login } from '@plone/volto/actions';
import { toast } from 'react-toastify';
import { Toast } from '@plone/volto/components';

import aheadSVG from '@plone/volto/icons/ahead.svg';
import clearSVG from '@plone/volto/icons/clear.svg';

import config from '@plone/volto/registry';

const messages = defineMessages({
    login: {
        id: 'Log in',
        defaultMessage: 'Log in',
    },
    loginName: {
        id: 'Login Name',
        defaultMessage: 'Login Name',
    },
    Login: {
        id: 'Login',
        defaultMessage: 'Login',
    },
    password: {
        id: 'Password',
        defaultMessage: 'Password',
    },
    cancel: {
        id: 'Cancel',
        defaultMessage: 'Cancel',
    },
    error: {
        id: 'Error',
        defaultMessage: 'Error',
    },
    loginFailed: {
        id: 'Login Failed',
        defaultMessage: 'Login Failed',
    },
    loginFailedContent: {
        id:
            'Both email address and password are case sensitive, check that caps lock is not enabled.',
        defaultMessage:
            'Both email address and password are case sensitive, check that caps lock is not enabled.',
    },
    register: {
        id: 'Register',
        defaultMessage: 'Register',
    },
    forgotPassword: {
        id: 'box_forgot_password_option',
        defaultMessage: 'Forgot your password?',
    },
    notice: {
        id: "pre_login_notice",
        defaultMessage: "This is a Federal computer system and is the property of the United States Government. It is for authorized use only. Users (authorized or unauthorized) have no explicit or implicit expectation of privacy. Any or all uses of this system and all files on this system may be intercepted, monitored, recorded, copied, audited, inspected, and disclosed to authorized site, Department of Energy, and law enforcement personnel, as well as authorized officials of other agencies, both domestic and foreign. By using this system, the user consents to such interception, monitoring, recording, copying, auditing, inspection, and disclosure at the discretion of authorized site or Department of Energy personnel. Unauthorized or improper use of this system may result in administrative disciplinary action and civil and criminal penalties. By continuing to use this system you indicate your awareness of and consent to these terms and conditions of use. LOG OFF IMMEDIATELY if you do not agree to the conditions stated in this warning."
    }
});

/**
 * Login class.
 * @class Login
 * @extends Component
 */
class Login extends Component {

    /**
     * Property types.
     * @property {Object} propTypes Property types.
     * @static
     */
    static propTypes = {
        login: PropTypes.func.isRequired,
        error: PropTypes.shape({
            message: PropTypes.string,
        }),
        loading: PropTypes.bool,
        token: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
        returnUrl: PropTypes.string,
    };

    /**
     * Default properties.
     * @property {Object} defaultProps Default properties.
     * @static
     */
    static defaultProps = {
        error: null,
        loading: null,
        token: null,
        returnUrl: null,
    };

    state = {
        can_login: false
    }

    /**
     * Constructor
     * @method constructor
     * @param {Object} props Component properties
     * @constructs WysiwygEditor
     */
    constructor(props) {
        super(props);
        this.onLogin = this.onLogin.bind(this);
    }

    /**
     * Component will receive props
     * @method componentWillReceiveProps
     * @param {Object} nextProps Next properties
     * @returns {undefined}
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.token) {
            this.props.history.push(this.props.returnUrl || '/');
            if (toast.isActive('loggedOut')) {
                toast.dismiss('loggedOut');
            }
            if (toast.isActive('loginFailed')) {
                toast.dismiss('loginFailed');
            }
        }
        if (nextProps.error) {
            if (toast.isActive('loggedOut')) {
                toast.dismiss('loggedOut');
            }
            if (!toast.isActive('loginFailed')) {
                toast.error(
                    <Toast
                        error
                        title={this.props.intl.formatMessage(messages.loginFailed)}
                        content={this.props.intl.formatMessage(messages.loginFailedContent)}
                    />,
                    { autoClose: false, toastId: 'loginFailed' },
                );
            }
        }
    }

    componentWillUnmount() {
        if (toast.isActive('loginFailed')) {
            toast.dismiss('loginFailed');
        }
    }

    /**
     * On login handler
     * @method onLogin
     * @param {Object} event Event object.
     * @returns {undefined}
     */
    onLogin(event) {
        this.props.login(
            document.getElementsByName('login')[0].value,
            document.getElementsByName('password')[0].value,
        );
        event.preventDefault();
    }

    /**
     * Render method.
     * @method render
     * @returns {string} Markup for the component.
     */
    render() {
        return (
            <div id="page-login">
                <Helmet title={this.props.intl.formatMessage(messages.Login)} />
                <Container text>
                    <Form method="post" onSubmit={this.onLogin}>
                        <Segment.Group raised>
                            <Segment className="primary">
                                <FormattedMessage id="Log In" defaultMessage="Login" />
                            </Segment>
                            <Segment secondary>
                                <FormattedMessage
                                    id="Sign in to start session"
                                    defaultMessage="Sign in to start session"
                                />
                            </Segment>
                            <Segment className="form">
                                <Form.Field inline className="help">
                                    <Grid>
                                        <Grid.Row stretched>
                                            <Grid.Column width="4">
                                                <div className="wrapper">
                                                    <label htmlFor="login">
                                                        <FormattedMessage
                                                            id="Login Name"
                                                            defaultMessage="Login Name"
                                                        />
                                                    </label>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column width="8">
                                                {/* eslint-disable jsx-a11y/no-autofocus */}
                                                <Input
                                                    id="login"
                                                    name="login"
                                                    placeholder={this.props.intl.formatMessage(
                                                        messages.loginName,
                                                    )}
                                                    autoFocus
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form.Field>
                                <Form.Field inline className="help">
                                    <Grid>
                                        <Grid.Row stretched>
                                            <Grid.Column stretched width="4">
                                                <div className="wrapper">
                                                    <label htmlFor="password">
                                                        <FormattedMessage
                                                            id="Password"
                                                            defaultMessage="Password"
                                                        />
                                                    </label>
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column stretched width="8">
                                                <Input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    placeholder={this.props.intl.formatMessage(
                                                        messages.password,
                                                    )}
                                                    tabIndex={0}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form.Field>
                                <Form.Field inline className="help">
                                    <Grid>
                                        <Grid.Row stretched>
                                            {config.settings.showSelfRegistration && (
                                                <Grid.Column stretched width="12">
                                                    <p className="help">
                                                        <Link to="/register">
                                                            {this.props.intl.formatMessage(messages.register)}
                                                        </Link>
                                                    </p>
                                                </Grid.Column>
                                            )}
                                            <Grid.Column stretched width="12">
                                                <p className="help">
                                                    <Link to="/passwordreset">
                                                        {this.props.intl.formatMessage(
                                                            messages.forgotPassword,
                                                        )}
                                                    </Link>
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form.Field>
                                <Form.Field inline className="notice">
                                    <Grid>
                                        <Grid.Row stretched>
                                            <Grid.Column stretched width="12">
                                                <div className="wrapper">
                                                    <Message
                                                    >
                                                        <Message.Header style={{ fontSize: "1rem", marginBottom: "5px" }}>NOTICE TO USERS</Message.Header>
                                                        <Message.Content>
                                                            {this.props.intl.formatMessage(
                                                                messages.notice,
                                                            )}
                                                            <Form.Checkbox
                                                                id="pre_login"
                                                                name="pre_login"
                                                                inline
                                                                label='I agree to the terms and conditions'
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        can_login: Boolean(e.target.checked)
                                                                    })
                                                                }}
                                                            />
                                                        </Message.Content>
                                                    </Message>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form.Field>
                            </Segment>
                            <Segment className="actions" clearing>
                                <Button
                                    basic
                                    primary
                                    icon
                                    floated="right"
                                    type="submit"
                                    id="login-form-submit"
                                    aria-label={this.props.intl.formatMessage(messages.login)}
                                    title={this.props.intl.formatMessage(messages.login)}
                                    loading={this.props.loading}
                                    disabled={!this.state.can_login}
                                >
                                    <Icon className="circled" name={aheadSVG} size="30px" />
                                </Button>

                                <Button
                                    basic
                                    secondary
                                    icon
                                    floated="right"
                                    id="login-form-cancel"
                                    as={Link}
                                    to="/"
                                    aria-label={this.props.intl.formatMessage(messages.cancel)}
                                    title={this.props.intl.formatMessage(messages.cancel)}
                                >
                                    <Icon className="circled" name={clearSVG} size="30px" />
                                </Button>
                            </Segment>
                        </Segment.Group>
                    </Form>
                </Container>
            </div>
        );
    }
}

export default compose(
    withRouter,
    injectIntl,
    connect(
        (state, props) => ({
            error: state.userSession.login.error,
            loading: state.userSession.login.loading,
            token: state.userSession.token,
            returnUrl:
                qs.parse(props.location.search).return_url ||
                props.location.pathname
                    .replace(/\/login\/?$/, '')
                    .replace(/\/logout\/?$/, '') ||
                '/',
        }),
        { login },
    ),
)(Login);
