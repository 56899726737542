import '@plone/volto/config';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
    ListingFields: {
        id: 'ListingFields',
        defaultMessage: 'Listing Fields',
    },
    label: {
        id: 'Label',
        defaultMessage: 'Label',
    },
    field: {
        id: 'Field',
        defaultMessage: 'Field',
    },
    sortable: {
        id: 'sortable',
        defaultMessage: 'Alternative Sorting Field'
    }
});


const ListingFieldsSchema = ({ intl }) => ({

    title: intl.formatMessage(messages.ListingFields),
    fieldsets: [
        {
            id: 'default',
            title: 'Default',
            fields: ['title', 'field', 'sortable' , 'link'],
        },
    ],
    properties: {
        title: {
            title: intl.formatMessage(messages.label),
        },
        field: {
            title: intl.formatMessage(messages.field),
            vocabulary: { '@id': 'plone.app.vocabularies.MetadataFields' },
            widget: 'array'
        },
        link: {
            type: 'boolean',
            title: 'Link',
            default: false,
            description: 'Make this field a link the document.',
        },
        sortable: {
            title: intl.formatMessage(messages.sortable),
        },
    },
    required: ['field'],
});


// Search schema Enhancer for search block
const SchemaEnhancer = (config) => {

    config.blocks.blocksConfig.search.schemaEnhancer = (props) => {

        props.schema.properties.sortOnOptions['vocabulary'] = { '@id': 'plone.app.vocabularies.MetadataFields' }

        props.schema.fieldsets.push(
            {
                id: 'listingFields',
                title: props.intl.formatMessage(messages.ListingFields),
                fields: ['fields'],
            },
        )

        props.schema.properties.fields = {
            title: 'Fields',
            widget: 'object_list',
            schema: ListingFieldsSchema(props),
            // schemaExtender: enhanceSchema,
        }


        return props.schema;
    }
}

export default SchemaEnhancer;