import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';


export default function Pdf({ docURL, Id }) {



    const openPDF = () => {
        const pdf = window.open(docURL + "/@@images/file");
    }

    return (
        <Tooltip
            title="Document PDF"
        >
            <Link
                color="#000000"
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
                href={docURL + "/@@images/file"}
            >
                {Id} {" "}
                <PictureAsPdfIcon style={{ marginBottom: "-4px" }} fontSize="small" />
            </Link>
        </Tooltip>

    )
}

