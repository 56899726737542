import React from 'react';
import { Table } from 'semantic-ui-react';


const Row = (title, field, isDate = false) => {

    let displayField = field

    if (isDate) {
        try {
            const [year, month, day] = field.split('-');
            const date = new Date(year, month - 1, day); // Month is zero-based

            if (!isNaN(date)) {
                displayField = date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });
            }
        } catch (e) { }
    }

    return (
        <Table.Row>
            <Table.Cell verticalAlign="top">
                {title}
            </Table.Cell>
            <Table.Cell verticalAlign="top">
                {displayField}
            </Table.Cell>
        </Table.Row>
    )
}

const TSPTypePolicy = (content) => {
    return (
        <Table>
            <Table.Body >
                {Row('ID:', content?.docid)}
                {Row('Type:', content?.NNSAType?.title)}
                {Row('SLM Office:', content?.slm?.title || content?.slm[0]?.title)}
                {Row('OPI:', content?.OPI?.title || content?.OPI[0]?.title)}
                {Row('Status:', content?.status?.title)}
                {Row('Issue Date:', content?.issueDate, true)}
                {Row('Last Update:', content?.updatedDate, true)}
                {Row('CRD:', content?.crd?.title)}
                {Row('DNFSB:', content?.dnfsb?.title)}
                {Row('CTA:', content?.cta?.title)}
                {Row('Misc. Comments:', content?.miscComments)}

            </Table.Body>
        </Table>
    )
}

const TSPTypeDelegation = (content) => {
    return (
        <Table>
            <Table.Body >
                {Row('ID:', content?.docid)}
                {Row('Type:', content?.type?.title)}
                {content?.delegant && Row(
                    content.type === 'Designation' ? 'Designator:' : 'Delegant',
                    `${content?.delegant}, ${content?.delegantPosition || ''}`
                )}
                {Row(
                    content.type === 'Designation' ? 'Designee:' : 'Delegate',
                    `${content?.delegate ? content?.delegate + ', ' : ''} ${content?.delegatePosition || ''}`
                )}
                {Row('Status:', content?.status?.title)}
                {Row('Effective Update:', content?.effectiveDate, true)}
                {content?.updatedDate && Row('Last Update:', content?.updatedDate, true)}
                {Row('Re-delegation Allowed:', content?.reDelegationAllowed?.token)}
                {content?.reDelegationLim && Row('Re-delegation Limitations:', content?.reDelegationLim)}
                {content?.signatory && Row('Signatory', content?.signatory)}
                {content?.fean && Row('Federal Enterprise Architecture Number:', content?.fean)}
                {content?.authorities && Row('Authorities', content?.authorities)}


                {/* {Row('SLM Office:', content?.slm?.title || content?.slm[0]?.title)}
                {Row('OPI:', content?.OPI?.title || content?.OPI[0]?.title)}
                {Row('Issue Date:', content?.issueDate, true)}
                {Row('Last Update:', content?.updatedDate, true)}
                {Row('CRD:', content?.crd?.title)}
                {Row('DNFSB:', content?.dnfsb?.title)}
                {Row('CTA:', content?.cta?.title)}
                {Row('Misc. Comments:', content?.miscComments)} */}

            </Table.Body>
        </Table>
    )
}


const TableByType = ({ content }) => {

    const TSPType = content?.['@type'];

    if (TSPType === 'NNSA.Type.policy')
        return TSPTypePolicy(content)
    else if (TSPType === 'NNSA.Type.delegation')
        return TSPTypeDelegation(content)

    return <></>
}

export default TableByType;