import { Toast } from '@plone/volto/components';
import { Api } from '@plone/volto/helpers';
import { toast } from 'react-toastify';

// Function to save failed request to localStorage
export function saveFailedRequest(request) {

    if (typeof (Storage) !== "undefined") {

        const url = new URL(request.url);
        const pathname = url.pathname.replaceAll("/++api++", "")

        const serializedRequest = JSON.stringify({
            url: pathname,
            method: request.method,
            headers: request.headers,
            body: request._data, // Only stringify if body is not a complex object
        });
        localStorage.setItem('failedRequest', serializedRequest);
    }

}


export function retryFailedRequests() {

    if (typeof (Storage) !== "undefined") {

        const api = new Api()

        const serializedRequest = localStorage.getItem('failedRequest');
        localStorage.removeItem("failedRequest");


        if (serializedRequest) {

            const request = JSON.parse(serializedRequest);

            api[request.method.toLowerCase()](request.url, { data: request.body })
                .then(success => {
                    toast.success(
                        <Toast
                            success
                            title={"Verification completed. Request was submitted"}
                            content={""}
                        />,
                        { autoClose: false, toastId: 'requestSuccess' },
                    )
                })
                .catch(error => {
                    toast.error(
                        <Toast
                            error
                            title={"Request failed. Please try again later"}
                            content={""}
                        />,
                        { autoClose: false, toastId: 'requestFailed' },
                    )
                })
        }
    }
}