import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import ClickAwayListener from '@mui/material/ClickAwayListener';


export default function PopupSettingsMenu({ message, children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!open) setAnchorEl(event.currentTarget);
    else setAnchorEl(null)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAway = () => {
  }

  return (
    <React.Fragment>

      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', zIndex: "1400" }}>
        <Tooltip title={message}>
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <Settings />
          </IconButton>
        </Tooltip>
      </Box>

      <ClickAwayListener
        onClickAway={handleClickAway}
      >
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >

          {children}


        </Menu>


      </ClickAwayListener>

    </React.Fragment>
  );
}


PopupSettingsMenu.defaultProps = {
  message: "Settings"
}