import { Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';

export default ({ item, useInternalLinks, isNavigation }) => {


    const wrapWithLink = (title) => item?.external ? <a className='external-link' href={item['@id']}>{title}</a>: <Link to={!useInternalLinks ? item['@id'] : flattenToAppURL(item['@id'])}>{title}</Link>

    const mainComponent = () => {
        return (
            <Segment>
                <h5>{wrapWithLink(item.title)}</h5>
                {item?.description && <p>{item?.description}</p> }
        </Segment>
        )
    }

    return mainComponent()
};