import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Tooltip from '@mui/material/Tooltip';

import { Link as MUILink } from '@mui/material';
import { Link as InternalLink } from 'react-router-dom';

import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    link: {
        color: "#1976d2",
        '&:hover': {
            textDecoration: 'underline'
        }
    }
})


export default function Link({ url, value }) {

    const classes = useStyles();

    const isItemFromSameSite = () => {
        return url.includes(location.origin)
    }

    return (
        <Tooltip title="Document details">
            <div className={classes.link}>
                {
                    isItemFromSameSite() ? (
                        <InternalLink
                            to={url.replace(location.origin, '')} >
                            {value}
                        </InternalLink>
                    ) : (
                        <MUILink
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="hover"
                            href={url}
                        >
                            {value}
                            {/* <LaunchIcon style={{ marginBottom: "-5px" }} fontSize="small" /> */}
                        </MUILink>
                    )
                }
            </div>

        </Tooltip>

    )
}

