
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Label, TextArea, Button } from 'semantic-ui-react';

import { injectIntl } from 'react-intl';
import { FormFieldWrapper } from '@plone/volto/components';

const isValidJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};


const TextareaWidget = (props) => {

    const { id, maxLength, value, onChange, placeholder, isDisabled } = props;


    if (isValidJson(value)) {
        if (id.toLowerCase().endsWith("_xml") || id.toLowerCase().endsWith("_json")) {
            return <TextareaWidgetWithJSON {...props} />
        }
    }

    const [lengthError, setlengthError] = useState('');

    const onhandleChange = (id, value) => {
        if (maxLength & value?.length) {
            let remlength = maxLength - value.length;
            if (remlength < 0) {
                setlengthError(`You have exceed word limit by ${Math.abs(remlength)}`);
            } else {
                setlengthError('');
            }
        }
        onChange(id, value);
    };

    return (
        <FormFieldWrapper {...props} className="textarea">
            <TextArea
                id={`field-${id}`}
                name={id}
                value={value || ''}
                disabled={isDisabled}
                placeholder={placeholder}
                onChange={({ target }) =>
                    onhandleChange(id, target.value === '' ? undefined : target.value)
                }
            />
            {lengthError.length > 0 && (
                <Label key={lengthError} basic color="red" pointing>
                    {lengthError}
                </Label>
            )}
        </FormFieldWrapper>
    );
};



const TextareaWidgetWithJSON = (props) => {
    const textAreaRef = useRef(null);

    let { id, maxLength, value, onChange, placeholder, isDisabled } = props;
    const [lengthError, setlengthError] = useState('');

    value = JSON.stringify(JSON.parse(value), undefined, 4);

    const onhandleChange = (id, value) => {
        if (!isValidJson(value)) {
            setlengthError(`Wrong JSON Format`);
            return
        } else {
            setlengthError('');
        }

        if (maxLength & value?.length) {
            let remlength = maxLength - value.length;
            if (remlength < 0) {
                setlengthError(`You have exceed word limit by ${Math.abs(remlength)}`);
            } else {
                setlengthError('');
            }
        }

        // value = JSON.stringify(value);
        onChange(id, value);
    };

    return (
        <FormFieldWrapper {...props} className="textarea">
            <TextArea
                ref={textAreaRef}
                id={`field-${id}`}
                name={id}
                defaultValue={value || ''}
                disabled={isDisabled}
                placeholder={placeholder}
            />
            {lengthError.length > 0 && (
                <Label key={lengthError} basic color="red" pointing>
                    {lengthError}
                </Label>
            )}
            <Button
                style={{
                    margin: "1rem 0",
                    width: "fit-content",
                    background: "#5dadcd",
                    borderRadius: "5px",
                    color: "white",
                }}
                onClick={() =>
                    onhandleChange(id, textAreaRef.current?.ref?.current?.value === null ? undefined : textAreaRef.current.ref.current.value)
                }>
                Save
            </Button>
        </FormFieldWrapper >
    );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TextareaWidget.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    error: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    onChange: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    wrapped: PropTypes.bool,
    placeholder: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
TextareaWidget.defaultProps = {
    description: null,
    maxLength: null,
    required: false,
    error: [],
    value: null,
    onChange: null,
    onEdit: null,
    onDelete: null,
};

export default injectIntl(TextareaWidget);