import * as React from 'react';

export default function useLocalStorage(key, initialValue, expiryInDays = 1) {
  const [storedValue, setStoredValue] = React.useState(() => {
    return getLocalStorage(key, initialValue, expiryInDays);
  });

  const setValue = value => {
    if (typeof window !== 'undefined') {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        setLocalStorage(key, valueToStore);
      } catch (error) {
        console.error(error);
      }
    }

  };

  return [storedValue, setValue];
}

export const getLocalStorage = (key, initialValue, expiryInDays = 3) => {
  if (typeof window !== 'undefined') {
    try {

      const item = window.localStorage.getItem(key);

      if (!item) {
        return null;
      }

      const parsedItem = JSON.parse(item);
      const now = Date.now();
      if (!parsedItem.timestamp || now - parsedItem.timestamp > expiryInDays * 24 * 60 * 60 * 1000) {
        window.localStorage.removeItem(key);
        return null;
      }
      return parsedItem.value;

    } catch (error) {
      console.error(error);
      return null;
    }
  }
  return null;
}

export const setLocalStorage = (key, valueToStore) => {
  if (typeof window !== 'undefined') {

    window.localStorage.setItem(
      key,
      JSON.stringify({ value: valueToStore, timestamp: Date.now() })
    );
  }
}
