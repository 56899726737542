import React, { useEffect } from "react"
import { Table } from 'semantic-ui-react';
import { defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react'
import { Icon } from '@plone/volto/components';
import vertical from '@plone/volto/icons/vertical.svg';

import moment from 'moment';

// import { getQueryStringResults } from '@plone/volto/actions';
// import { Api } from '@plone/volto/helpers';
import { useSelector, useDispatch } from 'react-redux';

import "./ListingTableVariation.less";

const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };


export const ListingTableVariationView = (props) => {

    // const searchbloock = useSelector(state => state.cookieConsentInfos.result)
    const sortOrder = 'ascending';

    const isSortable = (field) => {
        if (props.sortOnOptions?.includes(field.field) || field?.sortable) {
            return true
        }

        return false
    }


    const setSort = (on) => {
        if (props.sort_on === on) {
            if (props.sort_order === 'ascending') props.setSort(on, 'descending');
            else if (props.sort_order === 'descending') props.setSort(on, 'ascending');
        } else {
            props.setSort(on, 'ascending');
        }
    }

    const isValidDate = (field) => {
        const parsedDate = moment(field, true);
        return parsedDate.isValid()
        // const parsedDate = new Date(field);
        // return parsedDate instanceof Date && !isNaN(parsedDate);
    };

    const displayField = (field) => {

        if (field === null) return '';

        if (isValidDate(field)) {
            const parsedDate = new Date(field);
            const formattedDate = parsedDate.toLocaleDateString('en-US', DATE_OPTIONS);
            if (formattedDate !== "Invalid Date") {
                return <>{formattedDate}</>;
            }
        }

        if (Array.isArray(field)) {
            return <div>
                {field.map(f => <span>{f}</span>)}
            </div>
        }
        return <span>{field}</span>;
    };

    if (
        !props.items || props.items.length < 1
    ) return (<>No Results.</>)

    if (!props.fields || props.fields.length < 1) {

        return (<>No fields has been selected.</>)
    }

    return (
        <div style={{ overflowX: "scroll" }}>
            <Table className="ListingTable" celled striped>
                <Table.Header>
                    <Table.Row id="table-header">
                        {props.fields.map((field) =>
                            <Table.HeaderCell style={{ background: '#eceff1', color: 'black' }}>
                                {
                                    isSortable(field) ? (
                                        <Button
                                            basic
                                            color="black"
                                            icon
                                            style={{ whiteSpace: 'nowrap' }}
                                            onClick={() => setSort(field?.sortable || field.field)}
                                            className={props.sort_on === (field?.sortable || field.field) ? 'sorted' : null}
                                        >
                                            {field.title}
                                            <Icon name={vertical} size="15px" />
                                        </Button>
                                    ) : (<> {field.title} </>)
                                }
                            </Table.HeaderCell>
                        )}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.items.map((item) =>
                        <Table.Row>
                            {props.fields.map(({ field, link }, index) => (
                                <Table.Cell key={index}>
                                    {field && field.length ? (
                                        link ? (
                                            <Link to={item['@id']}>
                                                {field.map((f) => displayField(item[f]))}
                                            </Link>
                                        ) : (
                                            field.map((f) => displayField(item[f]))
                                        )
                                    ) : (
                                        <span>No data available</span>
                                    )}
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    )
}

const messages = defineMessages({
    fields: {
        id: 'Search block',
        defaultMessage: 'Search block',
    }
})



export const ListingTableVariation = {

    id: 'cards-id',
    title: 'Standards Table View 1',
    template: ListingTableVariationView,

}