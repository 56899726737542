import * as React from 'react'
import moment from 'moment';

import List from '@mui/material/List';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/styles'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { SearchAppContext } from '@nnsa/context';

// import { DatetimeWidget } from '@plone/volto/components';

const Date = styled(Box)(({ theme }) => ({

    padding: theme.spacing(0),
    marginBottom: theme.spacing(1),

    "& fieldset legend": {
        display: "none"
    }
}));

export default function FilterItemsDate({ name, addRemoveFilterItem }) {


    let initFrom = null
    let initTo = null
    let match = ""

    const dispatch = React.useContext(SearchAppContext);

    const usedFacet = (dispatch({ type: "GET", field: 'FACET_USED_OBJECT' }).data)
    if (usedFacet[name] && usedFacet[name][1]) {
        const regex = /\+([a-zA-Z_]+):\[(.+?) TO (.+?)\]/
        match = usedFacet[name][1][0].match(regex);

        initFrom = match[2].substring(0, 10);
        initTo = match[3].substring(0, 10);
    }

    if (initFrom !== null) initFrom = new moment(initFrom)
    if (initTo !== null) initTo = new moment(initTo)


    const [From, setFrom] = React.useState(initFrom)
    const [To, setTo] = React.useState(initTo)


    const handleFromChange = (from) => {
        if (from && from._isValid) {
            setFrom(from)
        }
    }

    const handleToChange = (to) => {
        if (to && to._isValid) {
            setTo(to)
        }
    }


    React.useEffect(() => {
        if (From == null || To == null) return

        let from = null;
        let to = null;

        try {
            addRemoveFilterItem(`${name}:[${From.toISOString()} TO ${To.toISOString()}]`)
        } catch (error) {
            console.error(error)
        }

    }, [From, To])

    return (
        <List component="div" disablePadding
            sx={{ pl: 4 }}
        >

            <LocalizationProvider dateAdapter={AdapterMoment}>

                <Date>
                    <Typography>FROM</Typography>
                    <DatePicker
                        toolbarPlaceholder="FROM"
                        value={From}
                        onChange={(newValue) => {
                            handleFromChange(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField style={{ width: '92%' }} {...params} />
                        )}
                    />
                </Date>

                <Date>
                    <Typography>TO</Typography>
                    <DatePicker
                        toolbarPlaceholder="TO"
                        value={To}
                        onChange={(newValue) => {
                            handleToChange(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField style={{ width: '92%' }} {...params} />
                        )}
                    />
                </Date>

            </LocalizationProvider >

        </List >
    )

}