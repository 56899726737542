import React from 'react';
import { Segment, Grid, Tab, Modal, Label, Container, Button, Message, Menu, Table } from 'semantic-ui-react';

export default ({ content }) => {
    const { id_number, title } = content;

    return <Container id="TSPTypeNNSA">
        <div className='title'>{id_number}, {title}</div>
        {
            content?.file?.download ? <Message style={{ padding: '1rem' }}>
                <a target="_blank" href={content['@id'] + "/@@images/file"}>
                    {content?.file?.filename || 'Document'}
                </a>
                <span style={{ fontSize: "0.85em" }}>
                    {content?.file?.size !== undefined && ` ${convertBytes(content?.file?.size)}`}
                </span>
            </Message> : <></>
        }
        <Table basic='very' celled style={{ width: '45rem' }}>
            {Row('ID:', content?.id_number)}
            {Row('Duration of Charter Group:', content?.duration_of_chartergroup)}
            {Row('Date Signed:', content?.date_signed, true)}
            {Row('Signatory:', content?.signatory)}

        </Table>
    </Container >
}

function Row(title, field, isDate = false) {

    let displayField = field;

    if (isDate) {
        try {
            const [year, month, day] = field.split('-');
            const date = new Date(year, month - 1, day); // Month is zero-based

            if (!isNaN(date)) {
                displayField = date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });
            }
        } catch (e) { }
    }

    return (
        <Table.Row>
            <Table.Cell verticalAlign="top" style={{ background: '#ddd', paddingInline: '15px', fontWeight: '500' }}>
                {title}
            </Table.Cell>
            <Table.Cell verticalAlign="top" >
                {displayField}
            </Table.Cell>
        </Table.Row>
    )
}

function convertBytes(bytes) {
    return `${(bytes < 1 << 10)
        ? bytes.toFixed(2) + 'B' : (bytes < 1 << 20)
            ? (bytes / (1 << 10)).toFixed(2) + 'KB' : (bytes < 1 << 30)
                ? (bytes / (1 << 20)).toFixed(2) + 'MB' : (bytes / (1 << 30)).toFixed(2) + 'GB'
        }`;
}