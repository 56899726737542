import React, { useContext, useEffect, useState } from 'react'
import { Segment, Image, Grid, Button, Divider } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';

import './Header.less';

import Item from './SideMenuItem';

import {
    Anontools,
    Navigation,
} from '@plone/volto/components';

import { HeaderSearchForm } from "@nnsa/components/Views/SearchApp/";

// import Bars from '@plone/volto/icons/menu-alt.svg';
import MenuIcon from '@mui/icons-material/Menu';

import { Modal } from '@nnsa/helpers';

import { getNavigation } from '@plone/volto/actions';
import { useDispatch } from 'react-redux';

import config from '@plone/volto/registry';

import { connect } from 'react-redux';

// those links should show always
const SideMenuLinks = [
    { '@id': '/ac-resources', title: 'Directives Point of Contact' },
    { '@id': '/nnsa-directives-tools', title: 'NNSA Directives Tools' },
    { '@id': '/references', title: 'References' },
    { '@id': '/nnsa-archived-directives', title: 'Archives' },
    { '@id': '/reports', title: 'Reports' },
    { '@id': '/technical-standards-help', title: 'Help' },
    { '@id': '/email-notification', title: 'Email Notification' },
    { '@id': '/contact-us', title: 'Contact Us' }
]

// const onlyMobileLinks = [
//     { '@id': 'http://energy.gov/ehss/services', title: 'Services', external: true },
//     { '@id': 'http://energy.gov/ehss/policy-guidance-reports', title: 'Policy, Guidance Reports', external: true },
//     { '@id': 'http://energy.gov/ehss/mission', title: 'Mission', external: true },
//     { '@id': 'http://energy.gov/ehss/about-us', title: 'About Us', external: true },
//     { '@id': 'http://energy.gov/offices', title: 'Offices', external: true },
// ]

const DisplayItems = (items, useInternalLinks = false) => {
    return (
        items && items.map((item, i) => {
            return (
                <Item
                    key={i}
                    isNavigation={item?.review_state ? true : false}
                    useInternalLinks={useInternalLinks}
                    item={item}
                />
            )
        })
    )
}

const Header = ({ token }) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [navigation, setNavigation] = useState(null)
    const { settings } = config

    const Home = {
        title: "Home",
        '@id': `/`
    }

    useEffect(async () => {
        const currNavigation = await dispatch(getNavigation('', 1))
        if (!currNavigation) return

        setNavigation(currNavigation?.items)
    }, [])

    const logout = () => {
        history.push("/logout")
    }

    const MainHeader = () => {
        return (
            <Grid columns={4}>
                <Grid.Row>
                    <Grid.Column width={5} className="block-logo">
                        <a href='https://www.energy.gov/nnsa/national-nuclear-security-administration' className='logo'>
                            <Image src='/nnsa-logo.png' />
                            {/* <p>Office of Environment, Health, Safety & Security</p> */}
                        </a>
                    </Grid.Column>

                    <Grid.Column width={6} className="block-search">
                        <div id='SearchAppHeader'>
                            <HeaderSearchForm />
                        </div>
                    </Grid.Column>

                    <Grid.Column width={1} className='mobile-only'>
                        <Link to='/revcom' className='revcom'>
                            <Image src="/revcom.png" />
                        </Link>
                    </Grid.Column>

                    <Grid.Column width={1} className="block-menu">
                        {/* {RightSideMenu()} */}
                        <Modal
                            id='header-side-menu'
                            style={{ top: 0, right: 0, margin: 0 }}
                            trigger={
                                <Button style={{ background: 'inherit', color: 'white' }}>
                                    <MenuIcon style={{ width: '3rem', height: '3rem', color: "#364272" }} />
                                </Button>}
                            actions={<div className="actions">
                                {token == undefined ? <Anontools /> : <Link onClick={logout}>Log out</Link>}
                            </div>}
                        >
                            <div className='only-mobile-links'>
                                <h2 class="documentFirstHeading">Navigation</h2>
                                {DisplayItems([Home], true)}
                                {DisplayItems(navigation, true)}
                                <Divider />
                            </div>
                            <h2 class="documentFirstHeading">Additional Resources</h2>
                            {DisplayItems(SideMenuLinks)}
                            {/* <div className='only-mobile-links'>
                                {DisplayItems(onlyMobileLinks)}
                            </div> */}
                            <Divider />
                        </Modal>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    return (
        <Segment basic role="banner" id="standards-header">
            <div className="header-1">
                <MainHeader />
            </div>
            <div className="header-2">
                <div>
                    <Navigation pathname={'/'} />
                </div>
                <div>
                    <Link to='/revcom-login' className='revcom' style={{ display: 'flex', alignItems: 'center' }}>
                        REVCOM &nbsp;<Image src="/revcom.png" />
                    </Link>
                </div>
            </div>
        </Segment>
    )
}

export default connect((state) => ({
    token: state.userSession.token,
}))(Header);