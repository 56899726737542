import React, { useContext } from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { SearchAppContext } from '@nnsa/context';
import { TableView, CardView, Actions, NoResult, AppliedFilters } from './views'
import { Box, Button, Paper, Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useViewport, useLocalStorage } from '@nnsa/hooks';
import { Loader } from '@nnsa/helpers'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import ClearAllIcon from '@mui/icons-material/ClearAll';


const SearchResult = () => {

    const theme = useTheme()
    const {width, isMobile} = useViewport();

    const [searchView, setSearchView] = useLocalStorage("searchView", "AutoView");

    const dispatch = useContext(SearchAppContext);

    const setSort = (field, direction) => {
        dispatch({ type: 'SET', function: 'SORT', field: field, direction: direction })
    }

    const searchResultView = () => {

        let element = ""
        let resultTotal = dispatch({ type: 'GET', field: 'TOTAL' }).data

        if (resultTotal == 0) {
            element = <NoResult
                setSort={setSort}
            />
        }

        else {

            switch (searchView) {

                case 'CardView':
                    element = <CardView />
                    break;

                case 'TableView':
                    element = <TableView
                        headers={dispatch({ type: 'GET', field: 'RESULT_FIELDS' }).data}
                        data={dispatch({ type: 'GET', field: 'RESULT' }).data}
                        linkon="title"
                        sort={dispatch({ type: 'GET', field: 'SORT' }).data}
                        setSort={setSort}
                    />
                    break;

                default:
                case 'AutoView':
                    theme.breakpoints.values.md > width ? element = <CardView /> : element = <TableView
                        headers={dispatch({ type: 'GET', field: 'RESULT_FIELDS' }).data}
                        data={dispatch({ type: 'GET', field: 'RESULT' }).data}
                        linkon="title"
                        sort={dispatch({ type: 'GET', field: 'SORT' }).data}
                        setSort={setSort}
                    />
                    break;
            }
        }

        return element

    }


    const SearchResultSkeleton = (height, animation = "pulse") => {
        return <Skeleton variant="rectangular" height={height} animation={animation} />
    }

    const SearchResultLoadingComponet = () => {
        return (
            <Stack spacing={1}>
                {SearchResultSkeleton(80)}
                {SearchResultSkeleton(250)}
                {SearchResultSkeleton(250)}
                {SearchResultSkeleton(250)}
                {SearchResultSkeleton(250)}
            </Stack>
        )
    }


    const SearchResultErrorComponet = () => {
        return (
            <Box p={2}>
                <Box display="flex" alignContent="center" >
                    <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                        <ErrorOutlineIcon style={{ fontSize: "4rem" }} color="primary" />
                    </Box>
                    <Box p={1}>
                        <Typography variant="h5">
                            There was a problem while trying to load your result.
                        </Typography>
                        <Typography>
                            Please try again in few moments, or Reset Search if the issue persists
                        </Typography>
                    </Box>
                </Box>
                <Box style={{ marginTop: "20px", marginLeft: "75px" }}>
                <Button
                    onClick={() => dispatch({ type: 'RELOAD' })}
                >
                    <ReplayIcon />
                    Click here to Retry
                </Button>
                |
                <Button
                    onClick={() => dispatch({ type: 'RESET', function: 'ALL', overwriteLoadingErrorState: true })}
                >
                
                    <ClearAllIcon />
                    Reset Search
                </Button>
                </Box>
            </Box>
        )
    }

    const SearchResultMainComponent = () => {
        return (
            <Box>
                {
                    (
                        (
                            dispatch({ type: 'GET', field: 'RESULT_INFO' }).data?.total !== 0
                            && !dispatch({ type: 'CHECK', function: 'IS_EMPTY', field: 'FACET_USED_OBJECT' }).data
                        )
                        || !dispatch({ type: 'CHECK', function: 'IS_EMPTY', field: 'ADVANCED_FIELDS' }).data
                    ) && <AppliedFilters view="Table" />
                }
                <Actions
                    searchView={searchView}
                    setSearchView={setSearchView}
                />
                <Divider sx={{ m: 1 }} />
                {searchResultView()}
            </Box>
        )
    }


    return (
        <div style={{ padding: "5px" }}>
            <div style={{ height: '1px' }}>{dispatch().isUpdate ? <Loader /> : <></>}</div>
            {!dispatch().isLoading ? (!dispatch().hasError ? SearchResultMainComponent() : SearchResultErrorComponet()) : SearchResultLoadingComponet()}
        </div>
    )
}



export default SearchResult;