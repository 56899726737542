import { useEffect } from 'react';
import { BodyClass, toBackendLang } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { getNavigation } from '@plone/volto/actions';
import config from '@plone/volto/registry';

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.intl.locale);

  useEffect(() => {
    dispatch(
      getNavigation(
        config.settings.isMultilingual ? `/${toBackendLang(lang)}` : '/',
        config.settings.navDepth,
      ),
    );
  }, [dispatch, lang]);

  return (
    <Container className="view-wrapper">
      <BodyClass className="page-not-found" />
      <h1>
        <FormattedMessage
          id="This page does not seem to exist…"
          defaultMessage="This page does not seem to exist…"
        />
      </h1>
      <p style={{ fontSize: '16px' }}>
        We apologize for the inconvenience, but the page you were trying to access is not at this address.
        Try searching for the document you are looking for or <a href='/contact-form'>Contact Us</a> for assistance.
      </p>
      <p style={{ fontSize: '16px' }}>
        <FormattedMessage id="Thank you." defaultMessage="Thank you." />
      </p>
    </Container>
  );
};

export default withServerErrorCode(404)(NotFound);
