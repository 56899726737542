import React, { useState, useContext, useEffect } from 'react'
import { SearchAppContext } from '@nnsa/context';

import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
        marginBottom: theme.spacing(-1),
    },
    justify: {
        padding: '0 0 0 1rem',
        fontSize: '1.25rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block'
        },
    },
    main: {
        display: 'flex',

    }

}));


const SearchHeader = () => {

    const classes = useStyles();

    const dispatch = useContext(SearchAppContext)
    const [total, setTotal] = useState(0)

    useEffect(() => {

        const currTotal = dispatch({ type: 'GET', field: 'RESULT_INFO' }).data?.total

        if (Number.isInteger(currTotal)) setTotal(currTotal)


    }, [dispatch({ type: 'GET', field: 'RESULT_INFO' }).data])


    return (
        <div className={classes.main}>
            {total ? (
                <div className={classes.root}>
                    <div className={classes.justify}>
                        {Number(total).toLocaleString()} Search Results Found.
                    </div>
                </div>
            ) : <></>}
        </div>

    )
}

export default SearchHeader