import React from 'react';
import { Box, LinearProgress } from '@mui/material';


const Loader = (props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
    );
};

Loader.defaultProps = {
  message: 'Loading ..'
};

export default Loader;