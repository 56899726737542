import { map, omit } from 'lodash';
import { flattenToAppURL } from '@plone/volto/helpers';

const GET_QUERYSTRING_RESULTS = 'GET_QUERYSTRING_RESULTS';
const RESET_QUERYSTRING_RESULTS = 'RESET_QUERYSTRING_RESULTS';

const initialState = {
    error: null,
    items: [],
    total: 0,
    loaded: false,
    loading: false,
    batching: {},
    subrequests: {},
};

/**
 * querystringsearch reducer.
 * @function querystring
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function querystringsearch(state = initialState, action = {}) {
    switch (action.type) {
        case `${GET_QUERYSTRING_RESULTS}_PENDING`:
            return action.subrequest
                ? {
                    ...state,
                    subrequests: {
                        ...state.subrequests,
                        [action.subrequest]: {
                            ...(state.subrequests[action.subrequest] || {
                                items: [],
                                total: 0,
                                batching: {},
                            }),
                            items: [],
                            total: 0,
                            error: null,
                            loaded: false,
                            loading: true,
                        },
                    },
                }
                : {
                    ...state,
                    error: null,
                    loading: true,
                    loaded: false,
                };
        case `${GET_QUERYSTRING_RESULTS}_SUCCESS`:
            return action.subrequest
                ? {
                    ...state,
                    subrequests: {
                        ...state.subrequests,
                        [action.subrequest]: {
                            error: null,
                            items: map(action.result.items, (item) => ({
                                ...item,
                                '@id': flattenToAppURL(item['@id']),
                            })),
                            total: action.result.items_total,
                            loaded: true,
                            loading: false,
                            batching: { ...action.result.batching },
                            facets_count: action.result.facets_count,
                        },
                    },
                }
                : {
                    ...state,
                    error: null,
                    items: map(action.result.items, (item) => ({
                        ...item,
                        '@id': flattenToAppURL(item['@id']),
                    })),
                    total: action.result.items_total,
                    loaded: true,
                    loading: false,
                    batching: { ...action.result.batching },
                    facets_count: action.result.facets_count,
                };
        case `${GET_QUERYSTRING_RESULTS}_FAIL`:
            return action.subrequest
                ? {
                    ...state,
                    subrequests: {
                        ...state.subrequests,
                        [action.subrequest]: {
                            error: action.error,
                            items: [],
                            total: 0,
                            loading: false,
                            loaded: false,
                            batching: {},
                            facets_count: {},
                        },
                    },
                }
                : {
                    ...state,
                    error: action.error,
                    items: [],
                    total: 0,
                    loading: false,
                    loaded: false,
                    batching: {},
                    facets_count: {},
                };
        case RESET_QUERYSTRING_RESULTS:
            return action.subrequest
                ? {
                    ...state,
                    subrequests: omit(state.subrequests, [action.subrequest]),
                }
                : {
                    ...state,
                    error: null,
                    items: [],
                    total: 0,
                    loading: false,
                    loaded: false,
                    batching: {},
                    facets_count: {},
                };
        default:
            return state;
    }
}
