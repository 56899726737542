import { getLocalStorage } from "hooks";


export const getDefaultQuery = (_authenticator = '', siteName = 'nnsa', statusName = 'Current', defaultOperator = 'AND') => {

    const query = {
        searchapp: true,
        // _authenticator: _authenticator,
        SearchableText: 'getall',
        DirStatus: statusName,
        WhichSite: siteName,
        page: 1,
        perPage: 50,
        sort: "",
        fq: "",
        operator: defaultOperator,
        advancedFields: []
    }

    return query
    // return getLocalStorage('lastQuery', query)

}

export const updateDefaultQuery = (updatedQuery) => {

    const defaultQuery = getDefaultQuery();
    let defaultQueryHasUpdate = false;

    if (updatedQuery === null) return defaultQuery

    for (const key in updatedQuery) {
        if (updatedQuery.hasOwnProperty(key) && defaultQuery.hasOwnProperty(key)) {
            defaultQuery[key] = updatedQuery[key];

            defaultQueryHasUpdate = true;
        }
    }

    return defaultQuery;
};

export const serialize = (obj, prefix) => {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            if (Array.isArray(v)) {

                v.forEach((item, index) => {
                    str.push(encodeURIComponent(k) + "[]=" + JSON.stringify(item));
                });

            } else if (v !== null && typeof v === "object") {
                str.push(serialize(v, k));
            } else {
                str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
    }
    return str.join("&");
}

export const deserialize = (str) => {
    if (str === null || str == '') return null

    const obj = {};
    let pairs = str

    pairs = pairs.replace("?", "")
    pairs = pairs.split("&");

    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i];
        var indexOfEqual = pair.indexOf("=");
        var key = decodeURIComponent(pair.substring(0, indexOfEqual));
        var value = decodeURIComponent(pair.substring(indexOfEqual + 1));


        if (!isNaN(value) && value !== "") {
            value = Number(value);
        } else if (value === 'true') {
            value = true;
        } else if (value === 'false') {
            value = false;
        }

        if (key.includes('[]')) {

            key = key.replace('[]', '')
            value = JSON.parse(value);
            if (!obj.hasOwnProperty(key)) {
                obj[key] = [value]
            } else {
                obj[key].push(value)
            }

        } else {

            obj[key] = value;
        }
    }
    return obj;
}