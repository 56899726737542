import React, { useContext } from 'react';

import { SearchAppProvider, SearchAppContext } from '@nnsa/context';
import { useHistory } from 'react-router-dom'

import { ThemeProvider, Grid, Container } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { SearchHeader } from './SearchHeader';
import { SearchFilter } from './SearchFilter';

import { SearchResult } from './SearchResult';



import "./SearchApp.less";

const SearchApp = () => {

    const history = useHistory()
    const dispatch = useContext(SearchAppContext);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SearchHeader />
                </Grid>
                <Grid item sm={0} md={3}>
                    <SearchFilter />
                </Grid>
                <Grid item xs={12} md={9}>
                    <SearchResult />
                </Grid>
            </Grid>
        </Container>
    )
}


const theme = createTheme({
    typography: {
        fontFamily: "Karla, sans-serif",
        fontSize: "16",
        body1: {
            fontSize: "16"
        }
    }
})

export default () => (
    <ThemeProvider theme={theme}>
        <SearchApp />
    </ThemeProvider>
);